/* custom styling for AWs Authenticator login */
/* PwC colours */
[data-amplify-authenticator] {
  --amplify-components-authenticator-router-box-shadow: 0 0 16px var(--amplify-colors-overlay-10);
  --amplify-components-authenticator-router-border-width: 0;
  --amplify-components-authenticator-form-padding: var(--amplify-space-medium) var(--amplify-space-xl) var(--amplify-space-xl);
  --amplify-components-button-primary-background-color: #D04A02;
  --amplify-components-fieldcontrol-focus-box-shadow: 0 0 0 2px #D04A02;
  --amplify-components-tabs-item-active-border-color: var(--amplify-colors-neutral-100);
  --amplify-components-tabs-item-color: var(--amplify-colors-neutral-80);
  --amplify-components-tabs-item-active-color: var(--amplify-colors-purple-100);
  --amplify-components-button-link-color: var(--amplify-colors-red-100);
  background-color: #D04A02;
height: 100vh;
}
/* Aspen colours */
/* 
[data-amplify-authenticator] {
  --amplify-components-authenticator-router-box-shadow: 0 0 16px var(--amplify-colors-overlay-10);
  --amplify-components-authenticator-router-border-width: 0;
  --amplify-components-authenticator-form-padding: var(--amplify-space-medium) var(--amplify-space-xl) var(--amplify-space-xl);
  --amplify-components-button-primary-background-color: #34805A;
  --amplify-components-fieldcontrol-focus-box-shadow: 0 0 0 2px #34805A;
  --amplify-components-tabs-item-active-border-color: var(--amplify-colors-neutral-100);
  --amplify-components-tabs-item-color: var(--amplify-colors-neutral-80);
  --amplify-components-tabs-item-active-color: var(--amplify-colors-purple-100);
  --amplify-components-button-link-color: var(--amplify-colors-red-100);
  background-color: #34805A;
height: 100vh;
} */

.amplify-button--primary:hover {
  --amplify-internal-button-background-color: #4b8f6d;
  /* --amplify-internal-button-border-color: #fff */
  /* --amplify-internal-button-color: red; */
}


.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }


  to {
    transform: rotate(360deg);
  }
}

main {
  overflow-x: auto;
}

.centered-div {
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  padding-top: 10px;
  padding-bottom: 10px;
}

.padding {
  text-align: left;
  padding: 15px;
}

.background {
  background-color: #f6f6f6;
  min-height: 100vh;
}

.maincontent {
  padding: 10px;
  display: flex;
}

.menu {
  width: 15%;
}

.content {
  width: 85%;
  margin-left: 10px;
  background-color: white;
}

.sidebar {
  padding: 10px;
}

.appbar-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.appbar-container-right {
  padding-right: 10px;
}



/*  global Card styling */
.MuiCard-root {

  border-radius: 0.5em !important;

}

/* Complaints Tracker Details Page Starts */
.card {
  /* box-shadow: 0 4px 4px 0 rgba(27, 27, 55, 0.2); */
  transition: 0.3s;
  width: 100%;
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.12);
}

.card--container {
  padding: 16px 16px;
}

/* Complaints Tracker Details Page Ends */

/* Leo CSS Landing Page Starts */
.newComplaintCard {
  width: 399px;
  height: 100%;
  top: 216px;
  left: 331px;
  padding: var(--SpacingSpacing4) var(--SpacingSpacing4) var(--SpacingSpacing6) var(--SpacingSpacing6);
  gap: var(--SpacingSpacing4);
  border-radius: 8px 0px 0px 0px;
  opacity: 0px;
  background-color: white;
  /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); */
}

.bottomButtonStyle {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  width: 197px;
  height: 40px;
  border-radius: 7px;
  border: 4px solid;
  padding: 8px;
  gap: 8px;
  color: #415385;
  background-color: #415385;
}

.topButtonStyle {
  width: 134px;
  height: 24px;
  border-radius: 10px;
  border: 2px solid;
  padding: 3px;
  gap: 8px;
  color: white;
  background-color: #415385;
}

.card-heading {
  color: black;
  text-align: left;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: -0.4px;
}

.headingBody {
  color: #696969;
  text-align: left;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.4px;
  margin: 0;
}

.top-heading {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  height: 50%;
  /* 100% of viewport height */
  background-color: white;
  /* Adjust as needed */
}

.top-heading-content {
  width: 448px;
  height: 150px;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  background-color: white;
  margin-left: 43px;
  font-size: 16px;
  font-weight: 400;
}

/* Leo CSS Landing Page Ends */

/* Michaels Trascript Starts */
/* Transcript Styles */
.highlighted {
  background-color: yellow;
}

.v-unsat-sentiment {
  color: #ff0000;
}

.unsat-sentiment {
  color: #ffa500;
}

.neut-sentiment {
  color: #cccccc;
}

.sat-sentiment {
  color: #adff2f;
}

.v-sat-sentiment {
  color: #00ff00;
}

.transcript-card {
  margin: 0px;
}

/* Transcript item (single speaker paragraph */
.transcript-item {
  /* box-shadow: 0 4px 4px 0 rgba(27, 27, 55, 0.2); */
  transition: 0.3s;
  width: auto;
}

.transcript-text {
  width: 100%;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 0.9em !important;
  /* line-height: 1em; */
}

.transcript-part {
  display: flex;
  padding:16px;
  border-radius: 8px;
}

.transcript-part-adj {
  display: flex;
  justify-content: space-between;
}

.transcript-audio {
  display: flex;
  justify-content: flex-end;
}

.transcript-speaker-icon {
  margin-right: 15px;
}

.audio-timing-font {
  font-family: monospace;
  font-size: 14px;
  color: #cccccc;
  padding: 4px 8px;
  border-radius: 4px;
}

.speaker1 {
  background-color: #f0f0f0;
}

.speaker2 {
  background-color: #5396ed;
  color:#ffffff;
}

/* End Transcript Styles */
/* Michaels Trascript Starts */


/*  Speed Dial styling */
.speed-dial-tooltip {
  box-shadow: 0 4px 4px 0 rgba(27, 27, 55, 0.2) !important;
  color: black !important;
  font-size: 1em !important;
  border-radius: 8px !important;
  padding: 8px!important;
  max-width: none;
  white-space: nowrap;
}